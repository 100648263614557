<template>
	<v-layout class="h-100 flex-wrap" style="max-height: calc(100vh - 74px); overflow-y: auto">
		<v-flex class="w-25 border-right">
			<div class="listing-header d-flex px-3">
				<v-menu offset-y right bottom content-class="elevation-3" rounded="0">
					<template #activator="{ on, attrs }">
						<p class="sub-heading m-0" v-on="on" v-bind="attrs">
							{{ currentStatus.text }}
							<v-icon>mdi-menu-down</v-icon>
						</p>
					</template>
					<v-list>
						<v-list-item-group>
							<v-list-item
								v-for="(status, index) in userStatus"
								:key="index"
								@click="
									statusFilterData(status.value),
										(currentStatus = { text: status.text, value: status.value })
								"
							>
								<v-list-item-title class="d-flex align-items-center">
									{{ status.text }} <span class="circle" :class="status.status_color"></span>
									<v-spacer></v-spacer>
									<div class="cm-budge white--text" :class="status.status_color">
										{{ status.status_count }}
									</div>
								</v-list-item-title>
							</v-list-item>
						</v-list-item-group>
					</v-list>
				</v-menu>
				<v-spacer></v-spacer>

				<div class="fw-600">
					Current Records &nbsp;<v-chip color="primary" outlined>{{ userLists.length }}</v-chip>
				</div>
				<!-- <v-btn depressed tile color="blue darken-4" class="white--text" v-on:click="createUser()">
					<v-icon>mdi-plus</v-icon> New User
				</v-btn> -->
			</div>
			<div style="max-height: calc(100vh - 160px); overflow-y: auto">
				<div
					class="user-listing-card cursor-pointer border-bottom ma-1 bg-white elevation-3 rounded-lg"
					v-for="(user, index) in userLists"
					:key="index"
					:class="`list-items ${user.id == usersId ? 'active' : ''}`"
					@click="tabDetails(user.id)"
				>
					<div class="my-1 d-flex justify-space-between">
						<v-chip x-small label class="px-1" color="primary">
							<span style="font-size: 11px">{{ user.barcode }}</span></v-chip
						>
						<v-chip
							x-small
							label
							class="px-1"
							:color="user?.activated == 'Active' ? 'green' : 'red'"
							dark
						>
							<span style="font-size: 11px">{{ user?.activated }}</span></v-chip
						>
						<!-- <v-chip x-small label>active</v-chip> -->
					</div>
					<div class="d-flex align-center">
						<div class="avater mr-4 grey lighten-2">
							<template v-if="user?.profile_img">
								<img :src="user?.profile_img" />
							</template>
							<template v-else>
								<v-icon size="42px" class="pa-1" color="grey lighten-1">mdi-account</v-icon>
							</template>
						</div>
						<div class="inforamtion">
							<div class="name fw-600 text-capitalize" v-if="user && user.first_name">
								{{ user.first_name }}
							</div>
							<div class="designation primary--text fw-500 text-capitalize" v-if="user && user.role">
								{{ user.role }}
							</div>
							<div class="email fw-500" v-if="user && user.email">{{ user.email }}</div>
						</div>
					</div>
				</div>
			</div>

			<div
				v-if="totalUsers > userLists.length"
				class="my-2 text-center"
				@click="() => !pageLoading && loadMoreUsers()"
			>
				<v-btn color="primary">
					<v-icon v-if="!pageLoading" left>mdi-restore</v-icon>
					{{ pageLoading ? "Loading..." : "Load more" }}
				</v-btn>
			</div>
		</v-flex>

		<v-flex class="w-75 user-detail-layout">
			<div class="d-flex pt-4">
				<template>
					<v-tabs
						v-model="dataTab"
						background-color="transparent"
						color="blue"
						class="custom-tab-transparent border-bottom w-100"
						active-class="blue darken-4 text-white"
						hide-slider
						@change="changeTab"
					>
						<v-tab href="#overall"><v-icon small left>mdi-information-outline</v-icon>Overall </v-tab>
						<v-tab
							href="#permission"
							v-if="AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin', 'team_lead'])"
						>
							<v-icon small left>mdi-information-outline</v-icon> Permission
						</v-tab>
						<v-tab href="#password"> <v-icon small left>mdi-history</v-icon>Change Password</v-tab>
						<v-tab href="#leave"><v-icon small left>mdi-information-outline</v-icon>Leave</v-tab>
						<v-tab v-if="usersType != 'seo'" href="#claim"
							><v-icon small left>mdi-information-outline</v-icon>Claim</v-tab
						>
						<v-tab href="#activity"><v-icon small left>mdi-history</v-icon>Activity</v-tab>
					</v-tabs>
				</template>
				<v-spacer></v-spacer>
				<template v-if="false && userRole != 1">
					<v-chip
						color="blue darken-4"
						class="white--text mr-2"
						label
						style="letter-spacing: 0.8px"
						v-if="userDetail?.activated == 2"
						v-on:click="statusChange(1)"
					>
						Activate
					</v-chip>
					<v-chip
						color="red"
						class="white--text mr-2"
						label
						style="letter-spacing: 0.8px"
						v-on:click="statusChange(2)"
					>
						Deactivate
					</v-chip>
				</template>
			</div>

			<v-tabs-items v-model="dataTab" class="transparent">
				<v-tab-item value="overall">
					<div class="user-listing-card d-flex border-bottom">
						<div>
							<div class="avater grey lighten-2">
								<ImageTemplate :id="img_id" :src="userDetail?.profile_img"></ImageTemplate>
							</div>
							<v-btn
								depressed
								color="blue darken-4 white--text"
								class="mt-2"
								v-on:click="selectFile"
								:ripple="false"
								plain
							>
								Change
							</v-btn>
						</div>

						<v-flex class="inforamtion pl-4 w-100">
							<div class="name d-flex">
								<div class="d-flex">
									<h4 class="fw-600 mb-0">{{ userDetail?.display_name }}</h4>
									<v-chip
										v-if="userDetail?.activated == 1"
										color="green"
										class="white--text ml-2"
										outlined
										style="letter-spacing: 0.8px"
										small
									>
										Active
									</v-chip>
									<v-chip
										v-else
										color="red"
										class="white--text ml-2"
										outlined
										style="letter-spacing: 0.8px"
										small
									>
										Inactive
									</v-chip>
								</div>

								<v-spacer></v-spacer>
								<v-btn
									v-if="getPermission('user:update')"
									color="blue darken-4 text-white"
									class="mr-2"
									depressed
									tile
									v-on:click="editUser({ id: usersId, action: 'Update' })"
									><v-icon small left>mdi-more mdi-flip-h</v-icon>Edit</v-btn
								>
								<!-- <v-icon link class="icon-18" v-on:click="editUser({ id: usersId, action: 'Update' })"
									>mdi-pencil</v-icon
								> -->
							</div>
							<div
								class="designation primary--text fw-500"
								v-if="userDetail && userDetail.role && userDetail.role.role"
							>
								{{ userDetail.role.role }}
							</div>
							<div class="email mt-1 fw-500">
								<v-icon class="icon-18 primary--text">mdi-email-outline</v-icon>
								{{ userDetail.email }}
							</div>
							<div class="email mt-1 fw-500" v-if="getProvision(userDetail.provision_date) >= 0">
								<v-chip
									v-if="getProvision(userDetail.provision_date) >= 0"
									color="red"
									class="white--text ml-2"
									outlined
									style="letter-spacing: 0.8px"
									small
								>
									Probation
								</v-chip>
							</div>
						</v-flex>
					</div>
					<div class="user-information px-4 pt-2">
						<div class="fw-600 py-2">User Information</div>
						<div class="d-flex ml-2 mb-2">
							<div style="width: 110px">First Name</div>
							<div class="ml-8 fw-500" v-if="userDetail && userDetail.first_name">
								{{ userDetail.first_name }}
							</div>
							<em class="text-muted ml-8" v-else>no first name</em>
						</div>
						<div class="d-flex ml-2 mb-2">
							<div style="width: 110px">Last Name</div>
							<div class="ml-8 fw-500" v-if="userDetail && userDetail.last_name">
								{{ userDetail.last_name }}
							</div>
							<em class="text-muted ml-8" v-else>no last name</em>
						</div>
						<div class="d-flex ml-2 mb-2">
							<div style="width: 110px">Email</div>
							<div class="ml-8 fw-500 primary--text" v-if="userDetail && userDetail.email">
								{{ userDetail.email }}
							</div>
							<em class="text-muted ml-8" v-else>no email</em>
						</div>
						<div class="d-flex ml-2 mb-2">
							<div style="width: 110px">Phone</div>
							<div class="ml-8 fw-500" v-if="userDetail && userDetail.phone_number">
								{{ userDetail.phone_number }}
							</div>
							<em class="text-muted ml-8" v-else>no phone number</em>
						</div>
						<div class="d-flex ml-2 mb-2">
							<div style="width: 110px">Joining Date</div>
							<div class="ml-8 fw-500" v-if="userDetail && userDetail.joining_date">
								{{ formatDate(userDetail.joining_date) }}
							</div>
							<em class="text-muted ml-8" v-else>no joining date</em>
						</div>
						<div class="d-flex ml-2 mb-2">
							<div style="width: 110px">Probation Period</div>
							<div class="ml-8 fw-500" v-if="userDetail && userDetail.provision_date">
								{{ formatDate(userDetail.joining_date) }} <b>To</b>
								{{ formatDate(userDetail.provision_date) }}
							</div>
							<em class="text-muted ml-8" v-else>no probation period</em>
						</div>
						<div class="d-flex ml-2 mb-2">
							<div style="width: 110px">Role</div>
							<div class="ml-8 fw-500" v-if="userDetail && userDetail.role">
								{{ userDetail.role.role }}
							</div>
							<em class="text-muted ml-8" v-else>no role</em>
						</div>
						<div class="d-flex ml-2 mb-2">
							<div style="width: 110px">Designation</div>

							<div class="ml-8 fw-500 text-capitalize" v-if="userDetail.profile">
								<template v-if="userDetail.profile == 'email-marketer'">Email Marketer</template>
								<template v-else>{{ userDetail.profile }}</template>
							</div>
							<em class="ml-8 text-muted" v-else>no designation</em>
						</div>
						<!-- <div class="d-flex mb-2">
							<div class="txt-label">Added By</div>
							<div class="value fw-500" v-if="userDetail">{{ userDetail.display_name }}</div>
						</div> -->
					</div>
				</v-tab-item>
				<v-tab-item value="permission">
					<UserPemission
						v-if="dataTab == 'permission'"
						:user-type="getValue(userDetail, 'users_type')"
						:uuid="usersId"
					></UserPemission>
				</v-tab-item>
				<v-tab-item value="password">
					<div class="mx-4">
						<div class="overflow-y" style="max-height: calc(100vh - 250px)">
							<v-layout class="p-4 border-bottom-light-grey min-height-57px">
								<v-flex class="font-level-3-bold my-auto">
									<span class="detail-svg-icon mr-2">
										<!--begin::Svg Icon-->
										<inline-svg :src="$assetURL('media/custom-svg/member-color.svg')" />
										<!--end::Svg Icon-->
									</span>
									Change Password
								</v-flex>
								<v-flex class="font-level-3-bold my-auto float-right">
									<v-btn
										:loading="pageLoading"
										class="white--text mr-2 float-right"
										depressed
										color="blue darken-4"
										tile
										:disabled="pageLoading"
										@click="updatePassword"
									>
										Update Password
									</v-btn>
								</v-flex>
							</v-layout>
						</div>
					</div>
					<v-form
						ref="passwordForm"
						v-model.trim="formValid"
						lazy-validation
						@submit.stop.prevent="updatePassword"
					>
						<v-row>
							<v-col md="6" class="py-0 pt-4" offset-md="3">
								<TextInput
									hide-details
									:disabled="pageLoading"
									:type="show1 ? 'text' : 'password'"
									:loading="pageLoading"
									:append-outer-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
									v-on:click:append-outer="show1 = !show1"
									id="password"
									placeholder="Password"
									:rules="[
										vrules.required(password, 'New Password'),
										vrules.minLength(password, 'New Password', 8),
										vrules.maxLength(password, 'New Password', 16),
									]"
									:class="{
										required: !password,
									}"
									v-model="password"
									@keypress="avoidSpaces"
								></TextInput>
							</v-col>
							<v-col md="6" class="py-0" offset-md="3">
								<TextInput
									hide-details
									:disabled="pageLoading"
									:type="show3 ? 'text' : 'password'"
									:loading="pageLoading"
									:append-outer-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
									v-on:click:append-outer="show3 = !show3"
									id="confirm-password"
									placeholder="Confirm Password"
									:rules="[
										vrules.required(password_confirmation, 'Confirm Password'),
										vrules.confirmPassword(password_confirmation, 'Confirm Password', password),
										vrules.minLength(password_confirmation, 'Confirm Password', 8),
										vrules.maxLength(password_confirmation, 'Confirm Password', 16),
									]"
									:class="{
										required: !password_confirmation,
									}"
									v-model="password_confirmation"
									@keypress="avoidSpaces"
								></TextInput>
							</v-col>
						</v-row>
					</v-form>
				</v-tab-item>
				<v-tab-item value="leave">
					<LeaveUpdate
						:user="usersId"
						:userDetail="userDetail"
						v-on:leave:updated="dataTab = 'leave'"
					></LeaveUpdate>
				</v-tab-item>
				<v-tab-item value="claim" v-if="usersType != 'seo'">
					<ClaimUpdate :user="usersId" v-on:leave:updated="dataTab = 'claim'"></ClaimUpdate>
				</v-tab-item>
				<v-tab-item class="user-activity-tab" v-if="dataTab == 'activity'" value="activity">
					<ActivityTimelineView
						v-if="usersId"
						:related-id="usersId"
						module="User"
						:loaded="Loaded.activity"
						@loaded="Loaded.activity = $event"
						:inlineStyle="{ height: 'calc(100vh - 116px)' }"
					></ActivityTimelineView>
				</v-tab-item>
			</v-tabs-items>
			<v-file-input ref="uploadFile" class="d-none" v-on:change="uploadFile($event)"></v-file-input>
		</v-flex>
		<AddUser
			:drawer="add_new_user"
			v-on:refreshUserList="getUserLists"
			v-on:refreshUser="getSingleUser"
			:user-data="userDetail"
			title-status="Update"
			:action-status="actionStatus"
			v-on:close="add_new_user = false"
		></AddUser>
	</v-layout>
</template>

<script>
import { GET, PATCH, QUERY, POST } from "@/core/services/store/request.module";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { toSafeInteger } from "lodash";
import MomentJS from "moment-timezone";
import objectPath from "object-path";
import { EventBus } from "@/core/event-bus/event.bus";
// import AddUser from "@/view/module/users/AddUser";
// import LeaveUpdate from "@/view/module/users/LeaveUpdate";
// import UserPemission from "@/view/module/users/UserPermission";
// import ClaimUpdate from "@/view/module/users/ClaimUpdate";
// import TextInput from "@/view/components/TextInput";
/* import ActivityTab from "@/view/pages/leads/components/ActivityTab"; */
// import ImageTemplate from "@/view/components/Image";
// import ActivityTimelineView from "@/view/components/ActivityTimelineView.vue";

export default {
	name: "user-detail",
	components: {
		AddUser: () => import("@/view/module/users/AddUser"),
		LeaveUpdate: () => import("@/view/module/users/LeaveUpdate"),
		UserPemission: () => import("@/view/module/users/UserPermission"),
		TextInput: () => import("@/view/components/TextInput"),
		/* ActivityTab, */
		ClaimUpdate: () => import("@/view/module/users/ClaimUpdate"),
		ImageTemplate: () => import("@/view/components/Image"),
		ActivityTimelineView: () => import("@/view/components/ActivityTimelineView.vue"),
	},
	data() {
		return {
			currentStatus: { text: "Active", value: 1 },
			img_id: +new Date(),
			actionStatus: "Create",
			selectedItem: 0,
			totalUsers: 0,
			customFilter: 1,
			add_new_user: false,
			current_date: new Date().toISOString().substr(0, 10),
			password_confirmation: null,
			password: null,
			/* 	dataTab: null, */
			profile_img: "",
			formValid: true,
			pageLoading: false,
			show1: false,
			activitylogData: [],
			/* 	probisionTime:0, */
			show3: false,
			userStatus: [],
			headers: [
				{
					text: "Image",
					value: "avatar",
					align: "start",
					sortable: false,
				},
				{
					text: "Name",
					align: "start",
					sortable: false,
					value: "name",
				},
				{
					text: "Email",
					value: "email",
					sortable: false,
				},
				{
					text: "Joining",
					value: "joining",
					sortable: false,
				},
				{
					text: "Role",
					value: "role",
					sortable: false,
				},
			],
			userLists: [],
			userDetail: {},
			page: 1,
			Loaded: {
				activity: false,
			},
			Login_User: new Object(),
			userRole: 0,
			usersType: null,
		};
	},
	methods: {
		getValue(object, key) {
			return objectPath.get(object, key);
		},
		loadMoreUsers() {
			this.page++;
			this.getUserLists();
		},
		uploadFile(e) {
			let formData = new FormData();
			formData.append("files", e);
			formData.append("id", this.userDetail.id ? this.userDetail.id : 0);
			this.$store
				.dispatch(POST, { url: `image-change`, data: formData })
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Success ! Image updated successfully.",
						},
					]);
					this.getSingleUser();
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		selectFile() {
			this.$refs["uploadFile"].$refs["input"].click();
		},
		getProvision(pro) {
			let a = MomentJS();
			let b = MomentJS(pro);
			return b.diff(a, "days");
		},
		updatePassword() {
			const _this = this;
			const formErrors = _this.validateForm(_this.$refs.passwordForm);
			if (formErrors.length) {
				_this.errors = [];
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.passwordForm.validate()) {
				return false;
			}

			this.$store
				.dispatch(PATCH, {
					url: `user/${this.usersId}/password`,
					data: {
						password: this.password,
					},
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Success ! Status updated successfully.",
						},
					]);
					this.password = null;
					this.password_confirmation = null;
					this.getSingleUser();
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		getLeaveDays() {
			const _this = this;
			_this.pageLoading = true;
			_this.$store
				.dispatch(GET, {
					url: "setting/leave",
				})
				.then(({ data }) => {
					_this.leaveList = data;
				})
				.catch((error) => {
					_this.logError(error);
					_this.goBack();
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		getUserLists() {
			this.pageLoading = true;
			this.$store
				.dispatch(QUERY, {
					url: "all-users",
					data: {
						status: this.currentStatus.value,
						page: this.page || 1,
					},
				})
				.then((data) => {
					this.userStatus = data.status;
					this.totalUsers =
						objectPath.get(data, `status.${+this.currentStatus.value || 0}.status_count`) || 0;
					if (this.page && this.page > 1) {
						this.userLists = [...this.userLists, ...data.tbody];
					} else if (this.page && this.page == 1) {
						this.userLists = [...data.tbody];
					}
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getSingleUser() {
			this.$store
				.dispatch(QUERY, {
					url: `single-user/${this.usersId}`,
				})
				.then((data) => {
					this.img_id = +new Date();
					this.userDetail = data;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		tabDetails(id) {
			const { name, params, query } = this.$route;
			this.$router.replace({
				name,
				query,
				params: {
					...params,
					id,
				},
			});
			this.usersId = id;
			this.getSingleUser();
			EventBus.$emit("refresh:tab");
		},
		changeTab(tab) {
			const { name, params, query } = this.$route;
			this.$router.push({ name, params, query: { ...query, tab } });
		},
		statusChange(status) {
			this.$store
				.dispatch(PATCH, {
					url: `user/${this.usersId}`,
					data: {
						status: status,
					},
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Success ! Status updated successfully.",
						},
					]);
					this.getSingleUser();
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		// userSetting() {
		// 	this.$store
		// 		.dispatch(GET, {
		// 			url: `user-setting`,
		// 		})
		// 		.then((data) => {
		// 			if (objectPath.get(data, "status")) {
		// 				this.userStatus = data.status;
		// 				this.totalUsers = objectPath.get(data, "status.0.status_count") || 0;
		// 			}
		// 		})
		// 		.catch((error) => {
		// 			console.log({
		// 				error,
		// 			});
		// 		});
		// },
		statusFilterData(value) {
			this.$store
				.dispatch(QUERY, {
					url: `all-users`,
					data: {
						status: value,
					},
				})
				.then((data) => {
					this.userLists = data.tbody; //data.rows;
					this.page = 1;
					if (value == "1") {
						this.totalUsers = data.status[1].status_count || 0;
					}
					if (value == "2") {
						this.totalUsers = data.status[2].status_count || 0;
					}
					if (value != "2" && value != "1") {
						this.totalUsers = data.status[0].status_count || 0;
					}
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		editUser(data) {
			const id = data.id;
			this.actionStatus = data.action;
			this.add_new_user = true;
			this.$store
				.dispatch(QUERY, { url: `single-user/${id}` })
				.then((data) => {
					this.userDetail = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		createUser() {
			this.add_new_user = true;
			this.actionStatus = "Create";
		},
	},
	watch: {
		usersId: {
			handler(id) {
				console.log({ id });
			},
		},

		/* "$route.query": {
			deep: true,
			handler(query) {
				const { tab } = query;
				this.dataTab = tab || 'overall';
			},
		}, */
	},
	mounted() {
		this.getUserLists();
		this.getProvision(this.userDetail.provision_date);
		this.getSingleUser();
		/* this.userSetting(); */
		this.getLeaveDays();
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Users", disabled: false, href: "/users" },
			{ text: "Detail", disabled: true },
		]);
	},
	beforeMount() {
		const { params } = this.$route;
		const { role, users_type } = this.AllowAnyOforGetRoleNType();
		this.usersId = toSafeInteger(params.id);
		this.userRole = role;
		this.usersType = users_type;
		/* 	this.dataTab = query.tab || "overall"; */
	},
	computed: {
		getFullName() {
			return "";
		},
		dataTab: {
			set(val) {
				let query = { ...this.$route.query };
				query.tab = val;
				if (val != this.dataTab) {
					this.$router.replace({ query });
				}
			},
			get() {
				return this.$route.query.tab || "overall";
			},
		},
	},
};
</script>
